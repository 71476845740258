import request from "@/utils/request";
// import { getToken } from "@/utils/auth";

export function getSysLog(query) {
  return request({
    url: "/admin/base/sysLogList",
    method: "get",
    params: query
  });
}
export function removeSysLog(data) {
  return request({
    url: "/admin/base/sysLogDel",
    method: "delete",
    data: {
      id: data
    }
  });
}
