<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
        <el-form-item label="时间">
          <el-date-picker
            v-model="times"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>
      <!-- 表格区域 -->

      <el-table :data="couponList" stripe style="width: 100%" border>
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="id" label="ID" width="100" align="center"></el-table-column>
        <el-table-column prop="method" label="请求类型"  align="center">
        </el-table-column>
        <el-table-column prop="url" label="Url地址"  align="left">
        </el-table-column>
        <el-table-column prop="user_name" label="操作员" align="center">
        </el-table-column>
        <el-table-column prop="user_id" label="操作员ID"  align="center">
        </el-table-column>
        <el-table-column prop="app" label="应用" align="center">
        </el-table-column>
        <el-table-column prop="ip" label="IP地址" align="center">
        </el-table-column>
        <el-table-column label="创建时间"  align="center">
          <template slot-scope="scope">
            {{$moment(scope.row.create_time * 1000).format("YYYY-MM-DD HH:mm:ss")}}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
              <el-button
                type="success"
                size="small"
                icon="el-icon-info"
                @click="toViewDetails(scope.row)"
                circle
              ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <div class="pagination">
        <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.page"
        :page-sizes="[5, 10, 50, 100]"
        :page-size="queryParams.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
      </div>

            <!-- 详情弹框 -->
      <el-dialog title="详情" :visible.sync="detailsDialog" width="50%">
        <el-form :model="detailsData">
          <el-form-item label="ID：" label-width="100px">
            {{ detailsData.id }}
          </el-form-item>

          <el-form-item label="请求地址：" label-width="100px">
            {{ detailsData.url }}
          </el-form-item>
          <el-form-item label="请求端：" label-width="100px">
            {{ detailsData.app }}
          </el-form-item>
          <el-form-item label="请求人员：" label-width="100px">
            {{ detailsData.user_name }}
          </el-form-item>
          <el-form-item label="请求人员ID：" label-width="100px">
            {{ detailsData.user_id }}
          </el-form-item>
          <el-form-item label="请求方式：" label-width="100px">
            {{ detailsData.method }}
          </el-form-item>
          <el-form-item label="请求IP：" label-width="100px">
            {{detailsData.ip}}
          </el-form-item>
          <el-form-item label="请求数据：" label-width="100px">
            {{detailsData.data}}
          </el-form-item>
           <el-form-item label="代理：" label-width="100px">
            {{detailsData.agent}}
          </el-form-item>
          <el-form-item label="请求时间：" label-width="100px">
            {{
              $moment(detailsData.create_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            }}
          </el-form-item>

        </el-form>
      </el-dialog>
    </el-card>

  </div>
</template>

<script>

import { getSysLog,removeSysLog} from "@/api/sysLog";
export default {
  data() {
    return {
      couponList: [],
      queryParams: {
        page: 1,
        limit: 10,

        code: "",
        start_time: 0,
        end_time: 0,

        goods_id:null
      },
      total: 0,
      times: null,
      detailsData: {}, //详情数据
      detailsDialog: false //详情弹框
    };
  },

  methods: {
     // 查看详情
    toViewDetails(row) {
      this.detailsData = row;
      this.detailsDialog = true;
    },
    handleSizeChange(size) {
      this.queryParams.limit = size;
      this.getCouponList();
    },
    handleCurrentChange(page) {
      this.queryParams.page = page;
      this.getCouponList();
    },

    // 获取全部系统日志
    getCouponList() {
      getSysLog(this.queryParams).then(res => {

        this.couponList = res.data.data;
        this.total = res.data.total;
      });
    },
    // 删除订单
    async removeOrder(i) {
      const result = await this.$confirm(
        "请问是否要永久删除该日志?",
        "删除提示",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).catch(err => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");

      removeSysLog(i).then(res => {
        if (res.code !== 1) {

          this.$message.error("删除失败");
        }
        this.$message.success("删除成功");
        this.getCouponList();
      });
    },

    // 查询
    onSubmit() {
      this.queryParams.page=1
      if (this.times) {
        this.queryParams.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryParams.end_time = new Date(this.times[1]).getTime() / 1000;
      }
      this.getCouponList();
    },
  },

  created() {
    this.getCouponList();
  }
}
</script>

<style scoped>
.el-row {
  margin-bottom: 30px;
  font-size: 24px;
}
.pagination {
  display: flex;
  justify-content: space-between;
}
::v-deep .el-pager li.active{
  color: #FF6688;
}
</style>
